import { List, ListItem } from '@mui/material';
import Header from '../../components/Shared/Header';
import styles from './styles.module.scss';

const MeetingSchedule = () => {
  return (
    <div className={styles.container}>
      <Header title='Meeting Schedule' direction='row' type='page' underlined />

      <div className={`${styles.sunday} ${styles.underlined}`}>
        <Header title='1st Sunday' direction='row' type='section' bold />
        <List className={styles.hour}>
          <div className={styles.time}>10:30 AM</div>
          <ListItem>Sacrament Meeting</ListItem>
        </List>

        <List className={styles.hour}>
          <div className={styles.time}>11:30 AM</div>
          <ListItem>Adult Sunday School</ListItem>
          <ListItem>Youth Sunday School</ListItem>
          <ListItem>Primary & Nursery</ListItem>
        </List>
      </div>

      <div className={`${styles.sunday} ${styles.underlined}`}>
        <Header title='2nd Sunday' direction='row' type='section' bold />
        <List className={styles.hour}>
          <div className={styles.time}>10:30 AM</div>
          <ListItem>Sacrament Meeting</ListItem>
        </List>

        <List className={styles.hour}>
          <div className={styles.time}>11:30 AM</div>
          <ListItem>Priesthood & Relief Society</ListItem>
          <ListItem>Young Men & Young Women</ListItem>
          <ListItem>Primary & Nursery</ListItem>
        </List>
      </div>

      <div className={`${styles.sunday} ${styles.underlined}`}>
        <Header title='3rd Sunday' direction='row' type='section' bold />
        <List className={styles.hour}>
          <div className={styles.time}>10:30 AM</div>
          <ListItem>Sacrament Meeting</ListItem>
        </List>

        <List className={styles.hour}>
          <div className={styles.time}>11:30 AM</div>
          <ListItem>Adult Sunday School</ListItem>
          <ListItem>Youth Sunday School</ListItem>
          <ListItem>Primary & Nursery</ListItem>
        </List>
      </div>

      <div className={`${styles.sunday} ${styles.underlined}`}>
        <Header title='4th Sunday' direction='row' type='section' bold />
        <List className={styles.hour}>
          <div className={styles.time}>10:30 AM</div>
          <ListItem>Sacrament Meeting</ListItem>
        </List>

        <List className={styles.hour}>
          <div className={styles.time}>11:30 AM</div>
          <ListItem>Priesthood & Relief Society</ListItem>
          <ListItem>Young Men & Young Women</ListItem>
          <ListItem>Primary & Nursery</ListItem>
        </List>
      </div>

      <div className={`${styles.sunday} ${styles.underlined}`}>
        <Header title='5th Sunday' direction='row' type='section' bold />

        <List className={styles.hour}>
          <div className={styles.time}>10:30 AM</div>
          <ListItem>Sacrament Meeting</ListItem>
        </List>

        <List className={styles.hour}>
          <div className={styles.time}>11:30 AM</div>
          <ListItem>Adults Combined</ListItem>
          <ListItem>Youth Combined</ListItem>
          <ListItem>Primary & Nursery</ListItem>
        </List>
      </div>
    </div>
  );
};

export default MeetingSchedule;
